import {
  SlAlert
} from "./chunk.2UV3QQ3Z.js";

// src/components/alert/alert.ts
var alert_default = SlAlert;
SlAlert.define("sl-alert");

export {
  alert_default
};
